import React, { useCallback, useEffect, useState } from "react";
import { Grid, Input } from "react-spreadsheet-grid";
import { getProjectsUser } from "../staffActions";
import { Button } from "semantic-ui-react";
import axios from "axios";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "../../../app/config/firebase";
import Swal from "sweetalert2";
import apiURL from "../../../app/config/apiURL";
import { getProfileClient } from "../../user/userActions";

const firestore = firebase.firestore();

const myRows = [];
const removeRows = [];

const MyGrid = (props) => {
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    var Param2 = parts[parts.length - 1];
    var Param3 = parts[parts.length - 2];
    const [projectList, setProjectList] = useState([]);
    const [projectIds, setProjectIds] = useState([]);
    const [rows, setRows] = useState(myRows);
    const [columns, setColumns] = useState([]);
    const [timeId, setTimeId] = useState("");
    const [timeRow, setTimeRow] = useState([]);
    const [loadingDay, setLoadingDay] = useState(false);
    const [loadingBulk, setLoadingBulk] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [contentChecked, setContentChecked] = useState(false);
    const [firstSave, setFirstSave] = useState(false);
    const [saving, setSaving] = useState(false);
    const [profile, setProfile] = useState([]);
    const [profileTime, setProfileTime] = useState([]);
    const [dateError, setDateError] = useState("");
    const [multiDateError, setMultiDateError] = useState("");
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        let id = "";

        if (Param2 === "input" || Param2 === "previous") {
            id = userId;
        } else {
            id = Param2;
        }
        getProfileClient(id).then(
            (res) => {
                if (res.exists) {
                    setProfile({ ...res.data(), id: res.id });

                    let data = res.data();
                    let list = [];

                    const keys = Object.entries(data);
                    for (const key of keys) {
                        if (key[0].split("|")[0] === "Required") {
                            list.push(key);
                        }
                    }

                    setProfileTime(list);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getProjectsUser(id).then(
            async (res) => {
                let list = [];
                let projIds = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                        projIds.push(doc.id);
                    }
                });

                // console.log(list);

                setProjectIds(projIds);

                // let projectList = [
                //     {
                //         key: "",
                //         text: "None",
                //         value: "",
                //     },
                // ];

                // let cnt = -1;
                // projectList = list.map(function (project) {
                //     cnt++;
                //     return {
                //         key: cnt,
                //         text: project.projectPrefix ? project.projectPrefix : project.projectName,
                //         value: project.id + "|" + project.projectName,
                //         hover: project.projectPrefix ? project.projectName : "",
                //         projectFromDate: project.projectFromDate ? project.projectFromDate : "",
                //         projectToDate: project.projectToDate ? project.projectToDate : "",
                //     };
                // });

                //call to laravel to get current time ID and rows entered already
                let type = "";
                let rowId = "";
                if (Param2 === "input") {
                    type = "input";
                } else if (Param2 === "previous") {
                    type = "previous";
                } else if (Param3 === "edit") {
                    type = "edit";
                    rowId = userId;
                } else if (Param3 === "previous_edit") {
                    type = "previous_edit";
                    rowId = userId;
                }

                const response = await axios.post(apiURL + "/api/get_time", { profile: id, projectList: projIds, type: type, rowId: rowId });

                let projectList = [];

                Object.keys(list).forEach(async function (key) {
                    if (
                        Number.isNaN(new Date(list[key].projectFromDate).getMonth()) === true ||
                        (parseFloat(response.data[5]) >= new Date(list[key].projectFromDate).getMonth() + 1 && parseFloat(response.data[5]) <= new Date(list[key].projectToDate).getMonth() + 1)
                    ) {
                        projectList.push({
                            text: list[key].projectPrefix ? list[key].projectPrefix : list[key].projectName,
                            value: list[key].id + "|" + list[key].projectName,
                            hover: list[key].projectPrefix ? list[key].projectName : "",
                        });
                    }
                });

                setProjectList(projectList);

                setTimeId(response.data[0]);
                setTimeRow(response.data[2]);

                if (response.data[3] == "yes") {
                    setDateError("You are trying to add days for multiple months");
                } else {
                    setDateError("");
                }

                if (response.data[4] == "yes") {
                    setMultiDateError("An Entry for this month already exists");
                } else {
                    setMultiDateError("");
                }

                if (myRows.length > 0 && myRows[0].id !== response.data[1][0].id) {
                    myRows.length = 0;
                    setRows(myRows);
                }

                if (myRows.length == 0) {
                    //map saved rows
                    for (var i = 0; i < response.data[1].length; i++) {
                        myRows.push(response.data[1][i]);
                    }
                    myRows.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

                    setRows(myRows);
                    setRows([].concat(rows));
                }

                let customTotalTimeWorked = 0;
                if (myRows.length > 0) {
                    customTotalTimeWorked =
                        Math.round(myRows.map((item) => (item.totalWorked !== "" && item.totalWorked !== null ? parseFloat(item.totalWorked) : 0)).reduce((prev, next) => prev + next) * 10) / 10;
                }

                let customTotalTimeRequired = 0;
                if (myRows.length > 0) {
                    customTotalTimeRequired = myRows.map((item) => (item.totalRequired !== "" ? parseFloat(item.totalRequired) : 0)).reduce((prev, next) => prev + next);
                }

                if (customTotalTimeWorked !== 0) {
                    var customTimeW = Math.round(((Math.round(customTotalTimeWorked * 10) / 10 / (Math.round(customTotalTimeRequired * 10) / 10)) * 100 * 10) / 10) + " %";
                } else {
                    var customTimeW = "0 %";
                }

                let initColumns = [
                    {
                        title: () => "",
                        value: (row, { focus }) => {
                            return <input className="SpreadsheetGridInput" type="checkbox" value={row.id} focus={focus} onChange={onFieldChange(row.id, "check", projectList)} />;
                        },
                        id: "check",
                        name: "mycheckboxes",
                        width: 3,
                    },
                    {
                        title: () => "Date",
                        value: (row, { focus, active }) => {
                            return (
                                <input
                                    type="date"
                                    className="SpreadsheetGridInput"
                                    min="2021-03-01"
                                    selected={row.date}
                                    value={row.date}
                                    focus={focus}
                                    onChange={onFieldChange(row.id, "date", projectList)}
                                    style={{ width: "100%" }}
                                />
                            );
                        },
                        id: "date",
                        width: 11,
                    },
                    {
                        title: () => "Day",
                        value: (row, { focus }) => {
                            return <Input value={row.day} focus={focus} onChange={onFieldChange(row.id, "day", projectList)} />;
                        },
                        id: "day",
                    },
                    {
                        title: () => "Total Required",
                        value: (row, { focus }) => {
                            return <Input value={row.totalRequired} focus={focus} onChange={onFieldChange(row.id, "totalRequired", projectList)} />;
                        },
                        id: "totalRequired",
                    },
                    {
                        title: () => "Total Worked (" + customTimeW + ")",
                        value: (row, { focus }) => {
                            return <Input value={row.totalWorked} focus={focus} onChange={onFieldChange(row.id, "totalWorked", projectList)} />;
                        },
                        id: "totalWorked",
                    },
                ];

                console.log(projectList);

                for (var i = 0; i < projectList.length; i++) {
                    // console.log(projectList[i]);
                    let name = projectList[i].value.split("|")[1];
                    let nameId = "project_" + projectList[i].value.split("|")[0];

                    if (customTotalTimeRequired !== 0) {
                        var amount =
                            Math.round(((myRows.map((item) => (item[nameId] == "" ? 0 : parseFloat(item[nameId]))).reduce((prev, next) => prev + next) / customTotalTimeRequired) * 100 * 10) / 10) +
                            " %";
                    } else {
                        var amount = "0 %";
                    }

                    let customname = name + "(" + amount + ")";

                    // console.log(projectList[i]);

                    initColumns.push({
                        // eslint-disable-next-line no-loop-func
                        title: () => customname,
                        // eslint-disable-next-line no-loop-func
                        value: (row, { focus }) => {
                            return <Input value={row[nameId]} focus={focus} onChange={onFieldChange(row.id, nameId, projectList)} />;
                        },
                        id: nameId,
                    });
                }

                initColumns.push({
                    title: () => "Comments",
                    value: (row, { focus }) => {
                        return <Input value={row.comments} focus={focus} onChange={onFieldChange(row.id, "comments", projectList)} />;
                    },
                    id: "comments",
                    width: 25,
                });

                setColumns(initColumns);

                setContentChecked(true);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const onFieldChange = (rowId, field, projectList) => async (value) => {
        const row = rows.find((elem) => elem.id === rowId);

        if (row[field] !== value) {
            if (firstSave === false) {
                setFirstSave(true);
            }
            setSaving(true);

            if (field === "date") {
                let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                row["day"] = weekday[new Date(value.target.value).getDay()];
                let hours = 0;
                if (row["day"] === "Monday") {
                    if (props.company.mondayHours !== undefined) {
                        hours = props.company.mondayHours;
                    }
                } else if (row["day"] === "Tuesday") {
                    if (props.company.tuesdayHours !== undefined) {
                        hours = props.company.tuesdayHours;
                    }
                } else if (row["day"] === "Wednesday") {
                    if (props.company.wednesdayHours !== undefined) {
                        hours = props.company.wednesdayHours;
                    }
                } else if (row["day"] === "Thursday") {
                    if (props.company.thursdayHours !== undefined) {
                        hours = props.company.thursdayHours;
                    }
                } else if (row["day"] === "Friday") {
                    if (props.company.fridayHours !== undefined) {
                        hours = props.company.fridayHours;
                    }
                }

                row["totalRequired"] = hours;
                row["date"] = value.target.value;

                myRows.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
                updateRows();
            }
            let project = false;
            if (field.split("_") && field.split("_")[0] === "project") {
                project = true;
                let total = 0;
                Object.keys(row).forEach((key) => {
                    if (key.split("_") && key.split("_")[0] === "project") {
                        if (key === field) {
                            let num = isNaN(parseFloat(value.replace(",", ".").replace(" ", "."))) ? parseInt(0) : parseFloat(value.replace(",", ".").replace(" ", "."));
                            total += num;
                        } else {
                            let num = isNaN(parseFloat(row[key])) ? parseInt(0) : parseFloat(row[key]);
                            total += num;
                        }
                    }
                });
                row["totalWorked"] = total;
                row[field] = value.replace(",", ".").replace(" ", ".");
            }

            if (field !== "date" && field !== "check" && project === false) {
                row[field] = value;
            }

            if (field === "check") {
                if (value.target.checked === true) {
                    removeRows.push(row.id);
                } else {
                    for (var a = 0; a < removeRows.length; a++) {
                        if (removeRows[a] === row.id) {
                            removeRows.splice(a, 1);
                        }
                    }
                }

                row[field] = value.target.checked;
            }

            let projects = [];
            Object.keys(projectList).forEach((key) => {
                projects.push(projectList[key].value.split("|")[0]);
            });

            setRows([].concat(rows));

            if (field !== "check") {
                if (Param2 === "input" || Param2 === "previous") {
                    let result = await axios.post(apiURL + "/api/update_time_row", { row: row, profileId: userId, type: Param2 });
                    if (result.data[0] == "yes") {
                        setDateError("You are trying to add days for multiple months");
                    } else {
                        setDateError("");
                    }

                    if (result.data[1] == "yes") {
                        setMultiDateError("An Entry for this month already exists");
                    } else {
                        setMultiDateError("");
                    }
                } else {
                    let result = await axios.post(apiURL + "/api/edit_time_row", { row: row, timeId: userId, user: props.currentUserProfile });
                    if (result.data[0] == "yes") {
                        setDateError("You are trying to add days for multiple months");
                    } else {
                        setDateError("");
                    }

                    if (result.data[1] == "yes") {
                        setMultiDateError("An Entry for this month already exists");
                    } else {
                        setMultiDateError("");
                    }
                }
            }
            setSaving(false);
        }

        let customTotalTimeWorked = 0;
        if (myRows.length > 0) {
            customTotalTimeWorked =
                Math.round(myRows.map((item) => (item.totalWorked !== "" && item.totalWorked !== null ? parseFloat(item.totalWorked) : 0)).reduce((prev, next) => prev + next) * 10) / 10;
        }

        let customTotalTimeRequired = 0;
        if (myRows.length > 0) {
            customTotalTimeRequired = myRows.map((item) => (item.totalRequired !== "" ? parseFloat(item.totalRequired) : 0)).reduce((prev, next) => prev + next);
        }

        if (customTotalTimeWorked !== 0) {
            var customTimeW = Math.round(((Math.round(customTotalTimeWorked * 10) / 10 / (Math.round(customTotalTimeRequired * 10) / 10)) * 100 * 10) / 10) + " %";
        } else {
            var customTimeW = "0 %";
        }

        let initColumns = [
            {
                title: () => "",
                value: (row, { focus }) => {
                    return <input className="SpreadsheetGridInput" type="checkbox" value={row.id} focus={focus} onChange={onFieldChange(row.id, "check", projectList)} />;
                },
                id: "check",
                name: "mycheckboxes",
                width: 3,
            },
            {
                title: () => "Date",
                value: (row, { focus, active }) => {
                    return (
                        <input
                            type="date"
                            className="SpreadsheetGridInput"
                            selected={row.date}
                            min="2021-03-01"
                            value={row.date}
                            focus={focus}
                            onChange={onFieldChange(row.id, "date", projectList)}
                            style={{ width: "100%" }}
                        />
                    );
                },
                id: "date",
                width: 11,
            },
            {
                title: () => "Day",
                value: (row, { focus }) => {
                    return <Input value={row.day} focus={focus} onChange={onFieldChange(row.id, "day", projectList)} />;
                },
                id: "day",
            },
            {
                title: () => "Total Required",
                value: (row, { focus }) => {
                    return <Input value={row.totalRequired} focus={focus} onChange={onFieldChange(row.id, "totalRequired", projectList)} />;
                },
                id: "totalRequired",
            },
            {
                title: () => "Total Worked (" + customTimeW + ")",
                value: (row, { focus }) => {
                    return <Input value={row.totalWorked} focus={focus} onChange={onFieldChange(row.id, "totalWorked", projectList)} />;
                },
                id: "totalWorked",
            },
        ];

        for (var i = 0; i < projectList.length; i++) {
            let name = projectList[i].value.split("|")[1];
            let nameId = "project_" + projectList[i].value.split("|")[0];

            if (customTotalTimeRequired !== 0) {
                var amount =
                    Math.round(((myRows.map((item) => (item[nameId] == "" ? 0 : parseFloat(item[nameId]))).reduce((prev, next) => prev + next) / customTotalTimeRequired) * 100 * 10) / 10) + " %";
            } else {
                var amount = "0 %";
            }

            let customname = name + "(" + amount + ")";

            initColumns.push({
                // eslint-disable-next-line no-loop-func
                title: () => customname,
                // eslint-disable-next-line no-loop-func
                value: (row, { focus }) => {
                    return <Input value={row[nameId]} focus={focus} onChange={onFieldChange(row.id, nameId, projectList)} />;
                },
                id: nameId,
            });
        }

        initColumns.push({
            title: () => "Comments",
            value: (row, { focus }) => {
                return <Input value={row.comments} focus={focus} onChange={onFieldChange(row.id, "comments", projectList)} />;
            },
            id: "comments",
            width: 25,
        });

        setColumns(initColumns);
    };

    const onColumnResize = (widthValues) => {
        const newColumns = [].concat(columns);
        Object.keys(widthValues).forEach((columnId) => {
            const column = columns.find((elem) => elem.id === columnId);
            column.width = widthValues[columnId];
        });
        setColumns(newColumns);
    };

    const updateRows = useCallback(() => {
        myRows.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
        setRows(myRows);
        setRows([].concat(rows));
    }, []);

    const updateTimeId = useCallback((id) => {
        setTimeId(id);
    }, []);

    async function addRow() {
        setLoadingDay(true);

        let thisTimeId = timeId;
        if (timeId === "0") {
            if (Param2 === "input" || Param2 === "previous") {
                // console.log(profile);
                const response = await axios.post(apiURL + "/api/create_time", { profile: props.profile, type: Param2 });
                await updateTimeId(response.data);
                thisTimeId = response.data;
            }
        }

        let projects = [];
        Object.keys(projectList).forEach((key) => {
            projects.push(projectList[key].value.split("|")[0]);
        });

        let profileId = "";
        let type = "";

        if (Param2 === "input") {
            profileId = props.profile["id"];
            type = "input";
        } else if (Param2 === "previous") {
            profileId = props.profile["id"];
            type = "previous";
        } else {
            profileId = Param2;
            type = "edit";
        }
        const response = await axios.post(apiURL + "/api/add_row", { timeId: thisTimeId, profileId: profileId, projects: projects, type: type, user: props.currentUserProfile });

        myRows.push(response.data);
        myRows.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
        updateRows();
        setLoadingDay(false);
    }

    async function addBulkRows() {
        setLoadingBulk(true);

        let fromDate = new Date(document.getElementById("fromDate").value);
        let toDate = new Date(document.getElementById("toDate").value);

        let thisTimeId = timeId;
        if (timeId === "0") {
            if (Param2 === "input" || Param2 === "previous") {
                const response = await axios.post(apiURL + "/api/create_time", { profile: props.profile, type: Param2 });
                await updateTimeId(response.data);
                thisTimeId = response.data;
            }
        }

        let profileId = "";
        let type = "";

        if (Param2 === "input") {
            profileId = props.profile["id"];
            type = "input";
        } else if (Param2 === "previous") {
            profileId = props.profile["id"];
            type = "previous";
        } else {
            profileId = Param2;
            type = "edit";
        }

        const response = await axios.post(apiURL + "/api/add_bulk_rows", {
            from: fromDate,
            to: toDate,
            profileId: profileId,
            projectIds: projectIds,
            timeId: thisTimeId,
            company: props.company,
            user: props.currentUserProfile,
            type: type,
        });
        let i = 0;

        let responseData = response.data[0];

        for (i = 0; i < responseData.length; i++) {
            myRows.push(responseData[i]);
            myRows.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
        }

        updateRows();

        if (response.data[1] == "yes") {
            setDateError("You are trying to add days for multiple months");
        } else {
            setDateError("");
        }

        if (response.data[2] == "yes") {
            setMultiDateError("An Entry for this month already exists");
        } else {
            setMultiDateError("");
        }

        setLoadingBulk(false);
    }

    async function removeSelected() {
        setLoadingDel(true);

        if (Param2 === "input") {
            var profileId = props.profile["id"];
        } else {
            var profileId = Param2;
        }

        let result = await axios.post(apiURL + "/api/delete_row", { idList: removeRows, timeId: timeId, profileId: profileId });

        if (result.data[0] == "yes") {
            setDateError("You are trying to add days for multiple months");
        } else {
            setDateError("");
        }

        if (result.data[1] == "yes") {
            setMultiDateError("An Entry for this month already exists");
        } else {
            setMultiDateError("");
        }

        for (var i = 0; i < removeRows.length; i++) {
            for (var a = 0; a < myRows.length; a++) {
                if (myRows[a].id === removeRows[i]) {
                    myRows.splice(a, 1);
                    updateRows();
                }
            }
        }

        setLoadingDel(false);
    }

    async function submitAllData() {
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to submit your time!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoadingSubmit(true);
                if (Param2 === "input" || Param2 === "previous") {
                    var profileId = props.profile["id"];
                } else {
                    var profileId = Param2;
                }
                let response = await axios.post(apiURL + "/api/submit_all", { rowList: myRows, profileId: profileId, timeId: timeId, submittedUser: props.currentUserProfile, profile: profile });
                await firestore.collection("email").add({
                    id: response.data.id,
                    status: response.data.status,
                    minDate: new Date(response.data.minDate),
                    maxDate: new Date(response.data.maxDate),
                    displayName: props.profile["displayName"],
                    approvalType: props.profile["approvalType"],
                    approvalUser: props.profile.hasOwnProperty("approvalUser") ? props.profile["approvalUser"] : "",
                    companyId: props.profile["companyId"],
                    departmentId: props.profile["departmentId"],
                });

                setLoadingSubmit(false);
                window.location.replace("/clients/time/" + props.profile.id);
            }
        });
    }

    let totalTimeWorked = 0;
    if (myRows.length > 0) {
        totalTimeWorked = Math.round(myRows.map((item) => (item.totalWorked !== "" && item.totalWorked !== null ? parseFloat(item.totalWorked) : 0)).reduce((prev, next) => prev + next) * 10) / 10;
    }

    let totalTimeRequired = 0;
    if (myRows.length > 0) {
        totalTimeRequired = myRows.map((item) => (item.totalRequired !== "" ? parseFloat(item.totalRequired) : 0)).reduce((prev, next) => prev + next);
    }

    // async function removeTimetrack(timeId, profileClient) {
    //     setDeleting(true);

    //     // await axios.post(apiURL + "/api/time_delete", { timeId: timeId, profileClient: profileClient });

    //     setDeleting(false);

    //     if (profileClient.hasOwnProperty("id")) {
    //         window.location.replace("/clients/time/" + profileClient.id);
    //     } else {
    //         window.location.replace("/clients/time/" + profile.id);
    //     }
    // }

    return (
        <>
            <div style={{ display: "flex", marginBottom: "50px" }}>
                {/* {profile.isSuper && (
                    <Button color="red" loading={deleting} disabled={deleting} onClick={() => window.confirm("Are you sure you wish to delete this item?") && removeTimetrack(timeId, profile)}>
                        DELETE
                    </Button>
                )} */}
                <div style={{ marginRight: "20px" }}>
                    <label>From Date:</label>
                    <br />
                    <input type="date" id="fromDate" min="2021-03-01" style={{ background: "#f4f4f6", borderRadius: "10px", border: "none", width: "300px", height: "36px", padding: "10px" }} />
                </div>
                <div style={{ marginRight: "20px" }}>
                    <label>To Date:</label>
                    <br />
                    <input type="date" id="toDate" min="2021-03-01" style={{ background: "#f4f4f6", borderRadius: "10px", border: "none", width: "300px", height: "36px", padding: "10px" }} />
                </div>
                <div style={{ position: "relative", width: "150px" }}>
                    <Button
                        type="button"
                        size="medium"
                        color="blue"
                        disabled={loadingDay || loadingDel || loadingSubmit}
                        loading={loadingBulk}
                        onClick={() => addBulkRows()}
                        style={{ position: "absolute", bottom: "0" }}
                    >
                        Add Bulk Day
                    </Button>
                </div>
            </div>

            {saving === true ? (
                <div style={{ float: "right", marginTop: "-110px" }}>
                    <div className="ui active inline loader"></div>
                    <p>Saving...</p>
                </div>
            ) : (
                firstSave === true && (
                    <div style={{ float: "right", marginTop: "-100px" }}>
                        <p style={{ color: "grey" }}>
                            Time Saved <i className="save outline icon"></i>
                        </p>
                    </div>
                )
            )}
            {dateError && dateError !== "" ? (
                <h2 style={{ color: "#721c24", backgroundColor: "#f8d7da", border: "1px solid #f5c6cb", borderRadius: "5px", width: "530px", padding: "10px" }}>{dateError}</h2>
            ) : null}
            {multiDateError && multiDateError !== "" ? (
                <h2 style={{ color: "#721c24", backgroundColor: "#f8d7da", border: "1px solid #f5c6cb", borderRadius: "5px", width: "530px", padding: "10px" }}>{multiDateError}</h2>
            ) : null}
            <small>
                <p style={{ paddingBottom: 10 }}>*Please double click to select date or checkbox</p>
            </small>

            {Param2 !== "input" && Param3 !== "previous_edit" && Param2 !== "previous" ? (
                <Button
                    type="button"
                    size="medium"
                    color="grey"
                    disabled={loadingDay || loadingBulk || loadingSubmit || loadingDel}
                    as={Link}
                    to={"/timetracking/view/" + userId}
                    style={{ float: "right", marginTop: "-80px" }}
                >
                    Back
                </Button>
            ) : (
                <>
                    {Param3 == "previous_edit" ? (
                        <Button
                            type="button"
                            size="medium"
                            color="grey"
                            disabled={loadingDay || loadingBulk || loadingSubmit || loadingDel}
                            as={Link}
                            to={"/clients/time/" + Param2}
                            style={{ float: "right", marginTop: "-80px" }}
                        >
                            Back
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            size="medium"
                            color="grey"
                            disabled={loadingDay || loadingBulk || loadingSubmit || loadingDel}
                            as={Link}
                            to={"/clients/time/" + userId}
                            style={{ float: "right", marginTop: "-80px" }}
                        >
                            Back
                        </Button>
                    )}
                </>
            )}

            <Button
                type="button"
                size="medium"
                color="red"
                disabled={loadingDay || loadingBulk || loadingSubmit}
                loading={loadingDel}
                onClick={() => removeSelected()}
                style={{ float: "right", marginTop: "-40px" }}
            >
                Delete Selected
            </Button>
            <Button
                type="button"
                size="medium"
                color="blue"
                loading={loadingDay}
                disabled={loadingBulk || loadingDel || loadingSubmit}
                onClick={() => addRow()}
                style={{ float: "right", marginTop: "-40px", marginRight: "150px" }}
            >
                Add Day
            </Button>

            {contentChecked === false ? (
                <div className="ui segment" style={{ height: 100 }}>
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader">Loading</div>
                    </div>
                    <p></p>
                </div>
            ) : (
                <>
                    <Grid
                        columns={columns}
                        rows={rows}
                        getRowKey={(row) => row.id}
                        isColumnsResizable
                        onColumnResize={onColumnResize}
                        disabledCellChecker={(row, columnId) => {
                            if (columnId === "totalRequired") {
                                return columnId === "totalRequired";
                            }
                            if (columnId === "totalWorked") {
                                return columnId === "totalWorked";
                            }
                            if (columnId === "day") {
                                return columnId === "day";
                            }
                        }}
                        focusOnSingleClick={true}
                    />

                    {myRows && myRows.length > 0 && (
                        <div style={{ paddingTop: 30 }}>
                            {Param2 === "input" || Param2 === "previous" ? (
                                <>
                                    {props.company.trackAlways === undefined ||
                                    props.company.trackAlways === true ||
                                    props.currentUserProfile.isAdmin === true ||
                                    props.term === true ||
                                    props.company.autoSubmit === false ||
                                    props.company.autoSubmit === undefined ? (
                                        <Button fluid size="large" type="button" color="red" disabled={loadingDay || loadingBulk || loadingDel} loading={loadingSubmit} onClick={() => submitAllData()}>
                                            SUBMIT TIME TRACKING
                                        </Button>
                                    ) : null}{" "}
                                </>
                            ) : null}

                            {timeRow &&
                                timeRow[0] &&
                                timeRow[0].times &&
                                timeRow[0].times.status &&
                                timeRow[0].times.status &&
                                timeRow[0].times.status !== undefined &&
                                (timeRow[0].times.status === "Progress Edit" ||
                                    timeRow[0].times.status === "In Progress Previous" ||
                                    timeRow[0].times.status === "Approved" ||
                                    timeRow[0].times.status === "Pending") && (
                                    <Button fluid size="large" type="button" color="red" disabled={loadingDay || loadingBulk || loadingDel} loading={loadingSubmit} onClick={() => submitAllData()}>
                                        SUBMIT TIME TRACKING
                                    </Button>
                                )}

                            {}
                        </div>
                    )}
                    <br />
                    {myRows && myRows.length > 0 && (
                        <>
                            <Table basic="very" celled collapsing unstackable style={{ width: "100%" }}>
                                <Table.Header>
                                    <Table.Row style={{ backgroundColor: "lightgrey", textAlign: "center" }} key="totals">
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>Total Required</Table.HeaderCell>
                                        <Table.HeaderCell>Total Worked</Table.HeaderCell>
                                        {projectList &&
                                            projectList.length > 0 &&
                                            projectList.map((entry) => (
                                                <>
                                                    <Table.HeaderCell>{entry.value.split("|")[1]}</Table.HeaderCell>
                                                </>
                                            ))}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell style={{ paddingLeft: 20 }}>
                                            <b>TOTAL Hours worked</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}>{myRows && myRows.length > 0 && <>{totalTimeRequired}</>}</Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}>{myRows && myRows.length > 0 && <>{totalTimeWorked}</>}</Table.Cell>
                                        {myRows && myRows.length > 0 && (
                                            <>
                                                {projectList &&
                                                    projectList.length > 0 &&
                                                    projectList.map((entry) => (
                                                        <>
                                                            <Table.Cell>
                                                                {Math.round(
                                                                    myRows
                                                                        .map((item) =>
                                                                            item["project_" + entry.value.split("|")[0]] == "" ? 0 : parseFloat(item["project_" + entry.value.split("|")[0]])
                                                                        )
                                                                        .reduce((prev, next) => prev + next) * 10
                                                                ) / 10}
                                                            </Table.Cell>
                                                        </>
                                                    ))}
                                            </>
                                        )}
                                    </Table.Row>
                                    <Table.Row key="totalHours">
                                        <Table.Cell style={{ paddingLeft: 20 }}>
                                            <b>Proportion Required</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}></Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}></Table.Cell>

                                        {projectList &&
                                            projectList.length > 0 &&
                                            projectList.map((entry) => (
                                                <>{profileTime && profileTime.map((time) => <>{entry.value.split("|")[0] === time[0].split("|")[1] && <Table.Cell>{time[1] + " %"}</Table.Cell>}</>)}</>
                                            ))}
                                    </Table.Row>
                                    <Table.Row key="totalPerc">
                                        <Table.Cell style={{ paddingLeft: 20 }}>
                                            <b>Proportion Worked</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}></Table.Cell>
                                        <Table.Cell style={{ paddingLeft: 20 }}>
                                            {myRows && myRows.length > 0 && (
                                                <>
                                                    {totalTimeWorked !== 0
                                                        ? Math.round(((Math.round(totalTimeWorked * 10) / 10 / (Math.round(totalTimeRequired * 10) / 10)) * 100 * 10) / 10) + " %"
                                                        : "0 %"}
                                                </>
                                            )}
                                        </Table.Cell>
                                        {myRows && myRows.length > 0 && (
                                            <>
                                                {projectList &&
                                                    projectList.length > 0 &&
                                                    projectList.map((entry) => (
                                                        <>
                                                            <Table.Cell>
                                                                {totalTimeRequired !== 0
                                                                    ? Math.round(
                                                                          ((myRows
                                                                              .map((item) =>
                                                                                  item["project_" + entry.value.split("|")[0]] == "" ? 0 : parseFloat(item["project_" + entry.value.split("|")[0]])
                                                                              )
                                                                              .reduce((prev, next) => prev + next) /
                                                                              totalTimeRequired) *
                                                                              100 *
                                                                              10) /
                                                                              10
                                                                      ) + " %"
                                                                    : "0 %"}
                                                            </Table.Cell>
                                                        </>
                                                    ))}
                                            </>
                                        )}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default MyGrid;
