let apiURL = process.env.REACT_APP_APIURL;
const hostname = window && window.location && window.location.hostname;

//Figure out which API URL to use
if (hostname === "think-drg.web.app" || hostname === "drgonline.think.org.za") {
    apiURL = "https://thinkapi.whitehart.co.za";
} else if (hostname === "drgonline.co.za" || hostname === "drg-outsourcing.web.app" || hostname === "drg-outsourcing.firebaseapp.com") {
    apiURL = "https://drgapi.whitehart.co.za";
} else if (hostname === "localhost") {
    apiURL = "https://thinkapi.whitehart.co.za";
    // apiURL = "https://drg-test-api.whitehart.co.za";
    // apiURL = "https://drgapi.whitehart.co.za";
}

export default apiURL;
