import React from "react";
import { Segment, Container, Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function ReportsDashboardPage() {
    return (
        <Container>
            <Grid stackable className="dashboard_blocks">
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Segment>
                            <h2>Leave Balance Report</h2>
                            <Button color="blue" as={Link} to="/reports/leave_due">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>

                    <Grid.Column>
                        <Segment>
                            <h2>Leave Activity</h2>
                            <Button color="blue" as={Link} to="/reports/leave_activity">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>

                    <Grid.Column>
                        <Segment>
                            <h2>Time Track Activity</h2>
                            <Button color="blue" as={Link} to="/reports/timetrack">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Segment>
                            <h2>Project Time Activity</h2>
                            <Button color="blue" as={Link} to="/reports/projects/timetrack">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <h2>Leave Transaction Report</h2>
                            <Button color="blue" as={Link} to="/reports/transaction">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <h2>Line Managers Per User</h2>
                            <Button color="blue" as={Link} to="/reports/line_managers">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Segment>
                            <h2>Leave Per User</h2>
                            <Button color="blue" as={Link} to="/reports/leave_user">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <h2>Long Service Report</h2>
                            <Button color="blue" as={Link} to="/reports/long_service">
                                Run Report
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}
